<template>
	<div>
		<div class="site-map">AUDIO</div>
		<b-row>
			<b-col cols="5">
				<img :src="getBookImgUrl()" alt="" />
			</b-col>
			<b-col cols="7">
				<div v-for="p in items" :key="p.id">
					<div class="book">{{p.title}}</div>
					<div v-for="q in p.chapterList" :key="q.id">
						<div class="chapter">{{q.title}}</div>
						<div class="d-flex list-audio">
							<div v-for="h in q.audioList" :key="h.id" class="audio">
								<a href="#" @click.prevent="onSelectAudio(h.fileName)" target="_blank">{{h.name}}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="audio-control">
					<audio controls id="myAudio" ref="myAudio">
						<source src="" type="audio/mpeg">
					</audio>
				</div>
				<div class="note">*** Click on file name to play</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import Constants from '../Contants'

	export default {
		name: 'AuthorList',
		data() {
			return {
				selectedAudio: '',
				items: [],
			}
		},
		mounted() {
			this.$store.commit('setPath', 'AUDIO');
			this.load();
		},
		methods: {
			getBookImgUrl() {
				return Constants.API_HOST + '/Public/Audio/BiaMKDSNM.jpg';
			},

			getImgUrl(fileName) {
				return Constants.API_HOST + `/Public/Audio/${fileName}`;
			},

			load() {
				this.$http.get('api/Misc/GetAudioList', (res) => {
					this.items = res;
				});
			},

			onSelectAudio(fileName) {
				this.$refs.myAudio.src = Constants.API_HOST + '/Public/Audio/' + fileName;
				this.$refs.myAudio.play();
			}
		}
	}
</script>

<style scoped>
	.book {
		font-weight: 700;
		margin-bottom: 20px;
	}
	.chapter {
		margin-bottom: 5px;
	}
	.list-audio {
		margin-left: 60px;
		margin-bottom: 10px;
	}
	.audio {
		margin-left: 5px;
	}
		.audio:not(:last-child):after {
			content: ', ';
		}
	img {
		width: 100%;
		padding-left: 5px;
	}
	.note {
		margin-top: 30px;
		font-style: italic;
	}
	.audio-control {
		margin-top: 20px;
	}
</style>

