<template>
	<div>
		<div class="site-map">REFERENCES</div>
		<div>
			<ol>
				<li v-for="p in items" :key="p.id" class="item">
					<a :href="p.link" target="_blank">{{p.title}}</a>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ReferencePage',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'REFERENCES');
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Misc/GetReferenceList', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.item {
		padding: 5px 0;
	}
</style>
