<template>
	<div>
		
	</div>
</template>

<script>
	

	export default {
		name: 'HomePageSide',
		components: {
		}
	}
</script>
