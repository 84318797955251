import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		sitePath: []
	},
	getters: {
		sitePath: state => { return state.sitePath; }
	},
	mutations: {
		setPath(state, payload) {
			state.sitePath = [payload];
		},

		addPath(state, payload) {
			state.sitePath.push(payload);
		}
	},
	actions: {}
});

