<template>
    <div>
        <div class="d-flex" v-if="page">
            <div class="back" v-if="!isFirst"><a href="#" @click.prevent="onMoveBack()">BACK</a></div>
            <div class="back" v-else>BACK</div>
            <div class="navi">{{page.firstItem}} - {{page.lastItem}} of {{page.itemCount}}</div>
            <div class="next" v-if="!isLast"><a href="#" @click.prevent="onMoveNext()">NEXT</a></div>
            <div class="next" v-else>NEXT</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ItemPaging',
        props: {
            page: null,
        },
        computed: {
            isFirst() {
                return this.page.firstItem == 1;
            },
            isLast() {
                return this.page.lastItem == this.page.itemCount;
            }
        },
        mounted() {
        },
        methods: {
            onMoveBack() {
                this.$emit('onMoveBack');
            },

            onMoveNext() {
                this.$emit('onMoveNext');
            }
        }
    }
</script>

<style scoped>
    .navi {
        margin: 0 10px 0 10px;;
    }
    a,
    a:link,
	a:visited,
	a:active,
	a:hover {
        padding: 2px 6px 2px 6px;
        background-color: #02913F;
        border-radius: 10px;
        color: #fff;
        font-size: 11px;
    }
    a:hover {
        text-decoration: none;
    }
</style>