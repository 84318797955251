<template>
	<div>
		<a href="#" @click.prevent="onShare(`https://www.facebook.com/sharer.php?u=${pageUrl}&t=${pageTitle}`)" title="Facebook Share"><img :src="require('../assets/SharedIcons/fb.png')" alt="fb" /></a>
		<a href="#" @click.prevent="onShare(`https://twitter.com/share?text=${pageTitle}&url=${pageUrl}`)" title="Twitter Share"><img :src="require('../assets/SharedIcons/tw.png')" alt="tw" /></a>
		<a href="#" @click.prevent="onShare(`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${pageTitle}`)" title="LinkedIn Share"><img :src="require('../assets/SharedIcons/in.png')" alt="in" /></a>
		<a href="#" @click.prevent="onShare(`https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${pageTitle}&body=${pageUrl}&ui=2&tf=1`)" title="Email Share"><img :src="require('../assets/SharedIcons/mail.png')" alt="fb" /></a>
	</div>
</template>

<script>
	export default {
		name: 'ShareLinks',
		props: [
			'title',
			'url'
		],
		computed: {
			pageTitle() {
				return encodeURIComponent(this.title);
			},

			pageUrl() {
				return encodeURIComponent(window.location.href);
			}
		},
		mounted() {
		},
		methods: {
			onShare(url) {
				window.open(url, 'sharer', 'toolbar=0,status=0,width=648,height=395');
			}
		}
	}
</script>