<template>
	<div id="abouts">
		<div class="site-map">ABOUT</div>
		<div v-for="p in items" :key="p.id" class="item">
			<div class="title">{{p.title}}</div>
			<div class="content" v-html="p.content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AboutPage',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'ABOUT');
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Misc/GetAboutList', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.title {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.item {
		padding: 5px 0;
		float: none;
		overflow: auto;
	}
</style>

<style>
	#abouts div.caption {
		color: #02913F;
		margin-bottom: 5px;
	}
</style>

