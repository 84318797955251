<template>
	<div>
		<div v-if="item">
			<div class="title">{{item.title}}</div>
			<div class="mt-2">
				<span class="create-date">{{item.createDate | moment("MMM DD, YYYY")}}</span>
				<span v-if="item.authors && item.authors.length > 0">
					<span class="sep">| by</span>
					<span class="authors">
						<span v-for="q in item.authors" :key="q.userID">
							<router-link :to="`/article/author/${q.userID}`">{{q.name}}</router-link>
							<img :src="getImgUrl(q.userID)" @error="onImgError($event)" />
						</span>
					</span>
				</span>
			</div>
			
			<div class="content" v-html="item.content"></div>
			<div class="d-flex">
				<div class="views">Views: {{item.views}}</div>
				<div class="ml-auto">
					<LikeIcon :item="item" />
				</div>
			</div>
			
			<br />
			<ShareLinks :title="item.title" :url="`/article/${item.itemID}`" />
			<br />
			<Disqus shortname="vietecology-org" :pageConfig="disqusConfig"/>
		</div>
	</div>
</template>

<script>
	import { Disqus } from 'vue-disqus';
	import Constants from '../Contants'
	import ShareLinks from '../components/ShareLinks.vue';
	import LikeIcon from '../components/LikeIcon.vue';

	export default {
		name: 'ArticlePage',
		components: {
			Disqus,
			ShareLinks,
			LikeIcon
		},
		watch: {
			$route(to) {
				this.load(to.params.id);
			}
		},
		computed: {
			articleID() {
				return this.$route.params.id;
			},
			disqusConfig() {
				return {
					url: window.location.href,
					identifier: this.articleID
				};
			}
		},
		data() {
			return {
				item: null
			}
		},
		mounted() {
			this.load(this.articleID);
		},
		methods: {
			load(id) {
				this.$http.get(`api/Article/GetArticleAndAuthors/${id}`, (res) => {
					var content = res.content;
					content = content.replace(/..\/..\/..\/..\/..\/..\/images\/Articles/gi, Constants.API_HOST + 'Public/Images/Articles');
					content = content.replace(/..\/..\/..\/..\/..\/images\/Articles/gi, Constants.API_HOST + 'Public/Images/Articles');
					content = content.replace(/..\/..\/..\/..\/images\/Articles/gi, Constants.API_HOST + 'Public/Images/Articles');
					content = content.replace(/..\/..\/..\/images\/Articles/gi, Constants.API_HOST + 'Public/Images/Articles');
					content = content.replace(/..\/..\/images\/Articles/gi, Constants.API_HOST + 'Public/Images/Articles');
					res.content = content;
					this.item = res;
				});
			},

			onImgError(event) {
				event.target.src = '/Images/Personal/non-author.png';
			},

			getImgUrl(id) {
				return `/Images/Personal/au${id}.jpg`;
			}
		}
	}
</script>

<style scoped>
	.title {
		font-size: 22px;
		font-family: Times New Roman, Segoe UI;
		font-weight: bold;
	}
	.authors {
		margin-bottom: 5px;
	}
	.create-date2 {
		color: #aaa;
		font-size: 11px;
	}
	.content {
		color: #121212;
		font-size: 16px;
		margin: 20px 0 20px;
	}
	.views {
		font-weight: bold;
	}
	.sep {
		margin: 0 5px 0 5px;
	}
	.authors img {
		width: 30px;
		height: 30px;
		border-radius: 20px;
		margin-left: 10px;
		margin-bottom: 5px;
	}
	.authors span:not(:last-child):after {
		content: ' - ';
	}
</style>

