<template>
	<div>
		<div class="site-map">GALLERY</div>

		<div class="list-photo">
			<div v-for="(p, index) in items" :key="index" class="item">
				<img :src="getImgUrl(p)" class="photo" alt="" @click="onZoomPhoto"/>
			</div>
		</div>

		<!-- The Modal -->
		<div id="myModal" ref="myModal" class="modal">

			<!-- The Close Button -->
			<span class="close" @click="onClose">&times;</span>

			<!-- Modal Content (The Image) -->
			<img class="modal-content" id="img01" ref="img01">

			<!-- Modal Caption (Image Text)
	<div id="caption"></div>-->
		</div>
	</div>
</template>

<script>
	import Constants from '../Contants'

	export default {
		name: 'GalleryPage',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'GALLERY');
			this.load();
		},
		methods: {
			getImgUrl(p) {
				return Constants.API_HOST + `/Public/Images/FotoGallery/${p.folder}/${p.fileName}`;
			},

			load() {
				this.$http.get('api/Misc/GetGalleryPhotos/1', (res) => {
					this.items = res;
				});
			},

			onZoomPhoto(evt) {
				// eslint-disable-next-line no-console
				//console.log(evt.target)

				this.$refs.myModal.style.display = "block";
				this.$refs.img01.src = evt.target.src;
			},

			onClose() {
				this.$refs.myModal.style.display = "none";
			}
		}
	}
</script>

<style scoped>
	.list-photo {
		line-height: 0;
		-webkit-column-count: 4;
		-webkit-column-gap: 5px;
		-moz-column-count: 4;
		-moz-column-gap: 5px;
		column-count: 4;
		column-gap: 5px;
	}
	.item {
		margin-bottom: 5px;
	}
	.photo {
		width: 100% !important;
		height: auto !important;
		cursor: pointer;
		transition: 0.3s;
	}
	.photo:hover {
		opacity: 0.7;
	}

	@media (max-width: 1200px) {
		.list-photo {
			-moz-column-count: 4;
			-webkit-column-count: 4;
			column-count: 4;
		}
	}

	@media (max-width: 1000px) {
		.list-photo {
			-moz-column-count: 3;
			-webkit-column-count: 3;
			column-count: 3;
		}
	}

	@media (max-width: 800px) {
		.list-photo {
			-moz-column-count: 2;
			-webkit-column-count: 2;
			column-count: 2;
		}
	}

	/* The Modal (background) */
	.modal {
		display: none; /* Hidden by default */
		position: fixed; /* Stay in place */
		z-index: 1; /* Sit on top */
		padding-top: 30px; /* Location of the box */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
	}

	/* Modal Content (Image) */
	.modal-content {
		margin: auto;
		display: block;
		width: 80%;
		max-width: 700px;
	}

	/* Caption of Modal Image (Image Text) - Same Width as the Image */
	#caption {
		margin: auto;
		display: block;
		width: 80%;
		max-width: 700px;
		text-align: center;
		color: #ccc;
		padding: 10px 0;
		height: 150px;
	}

	/* Add Animation - Zoom in the Modal */
	.modal-content, #caption {
		animation-name: zoom;
		animation-duration: 0.6s;
	}

	@keyframes zoom {
		from {
			transform: scale(0)
		}

		to {
			transform: scale(1)
		}
	}

	/* The Close Button */
	.close {
		position: absolute;
		top: 15px;
		right: 35px;
		color: #f1f1f1;
		font-size: 40px;
		font-weight: bold;
		transition: 0.3s;
	}
		.close:hover,
		.close:focus {
			color: #bbb;
			text-decoration: none;
			cursor: pointer;
		}

	/* 100% Image Width on Smaller Screens */
	@media only screen and (max-width: 700px) {
		.modal-content {
			width: 100%;
		}
	}
</style>

