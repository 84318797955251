<template>
	<div>
		<div class="site-map">PROJECTS</div>
		<div v-if="item">
			<div v-html="item.name" class="title"></div>
			<div v-html="item.content" class="content"></div>
			<br />
			<Disqus shortname="vietecology-org" :pageConfig="disqusConfig" />
		</div>
	</div>
</template>

<script>
	import { Disqus } from 'vue-disqus';

	export default {
		name: 'ProjectPage',
		components: {
			Disqus
		},
		watch: {
			$route(to) {
				this.load(to.params.id);
			}
		},
		computed: {
			projectID() {
				return this.$route.params.id;
			},

			disqusConfig() {
				return {
					url: window.location.href,
					identifier: this.projectID
				};
			}
		},
		data() {
			return {
				item: null
			}
		},
		mounted() {
			this.$store.commit('setPath', 'PROJECTS');
			this.load(this.projectID);
		},
		methods: {
			load(id) {
				if (id == undefined || id == null) {
					this.$http.get('api/Misc/GetLastProject', (res) => {
						this.item = res;
					});
				}
				else {
					this.$http.get(`api/Misc/GetProject/${id}`, (res) => {
						this.item = res;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.content {
	}
</style>
