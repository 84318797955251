<template>
	<div>
		<div class="site-map">DOCUMENTARY VIDEOS</div>
		<div v-if="items.length > 0">
			<div :set="p = curVideo">
				<div v-html="p.title" class="title"></div>
				<div class="container">
					<iframe :src="p.source" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="youtube"></iframe>
				</div>
				<div v-html="p.content" class="content"></div>
			</div>
			<div class="others">
				<div class="caption">Other Videos</div>
				<ol>
					<li v-for="p in items" :key="p.id" class="item">
						<router-link :to="`/topic/docvideos/${p.id}`" class="item">{{p.title}}</router-link>
					</li>
				</ol>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'VideoPage',
		watch: {
			$route(to) {
				this.load(to.params.id);
			}
		},
		computed: {
			videoID() {
				return this.$route.params.id;
			},

			curVideo() {
				if (this.items.length > 0) {
					var id = this.videoID;

					for (var p of this.items) {
						if (p.id == id)
							return p;
					}

					return this.items[0];
				}
				else
					return null;
			}
		},
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'DOCUMENTARY VIDEOS');
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Misc/GetVideoList/0', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.content {
		margin-top: 20px;
	}
	.container {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
	}
	.youtube {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.others {
		margin: 20px 0 10px 0;
		padding-top: 10px;
		border-top: solid 1px #ddd;
	}
	.caption {
		font-weight: 700;
	}

	li.item {
		padding: 5px 0;
	}
</style>
