<template>
	<div>
		<TheSameCategory />
		<HLine />
		<TheSameAuthor />
	</div>
</template>

<script>
	import TheSameCategory from './TheSameCategory.vue'
	import TheSameAuthor from './TheSameAuthor.vue'
	import HLine from './HLine.vue'

	export default {
		name: 'ArticleSide',
		components: {
			TheSameCategory,
			TheSameAuthor,
			HLine
		},
		computed: {
			articleID() {
				return this.$route.params.id;
			}
		},
	}
</script>
