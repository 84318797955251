<template>
	<div>
		<div class="site-map">SEARCH</div>
		<div>
			<b-container fluid>
				<b-row>
					<b-col cols="7">
						<div class="tag1">Articles contain text</div>
						<b-form-input type='search' v-model="textSearch" placeholder="Enter text" @keydown.enter.native="onSearch"></b-form-input>
					</b-col>
					<b-col cols="5">
						<b-form-group  v-slot="{ ariaDescribedby }">
							<b-form-radio-group v-model="searchOpt"
												:options="options"
												:aria-describedby="ariaDescribedby"
												name="radio-inline"
												class="options"
												@change="onChangedOpt">
							</b-form-radio-group>
						</b-form-group>	
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="7">
						<div class="tag2">Articles of author</div>
						<b-form-select 
							:options="authors" 
							v-model="selAuthor"
							value-field="id"
							text-field="name"
							@change="onChangedAuthor">
						</b-form-select>
					</b-col>
				</b-row>
			</b-container>
			<div v-if="startSearch">
				Searching...
			</div>
		</div>
		<div v-if="items.length > 0">
			<div class="results">Found Result: {{items.length}} items <span v-html="msg"></span></div>
			<ol>
				<li v-for="p in items" :key="p.itemID" class="item">
					<router-link :to="`/article/article/${p.itemID}`"><div v-html="p.title"></div></router-link>
				</li>
			</ol>
		</div>
		<div v-else class="results">
			No any item
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SearchPage',
		data() {
			return {
				msg: '',
				textSearch: '',
				searchOpt: 'title',
				startSearch: false,
				selAuthor: null,
				authors: [],
				items: [],
				options: [
					{ text: 'Title only', value: 'title' },
					{ text: 'Content only', value: 'content' },
					//{ text: 'Author', value: 'author' },
				]
			}
		},
		mounted() {
			this.$store.commit('setPath', 'SEARCH');
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Author/GetAuthorExList', (res) => {
						this.authors = res;
					});
			},

			getAuthor(id) {
				for (const p of this.authors) {
					if (p.id === id)
					{
						return p;
					}
				}
			},

			onChangedAuthor(id) {
				this.startSearch = true;
				this.items = [];

				this.$http.get(`api/Article/SearchArticlesOfAuthor/${id}`, (res) => {
					var p = this.getAuthor(id);
					this.items = res;
					this.msg = ` of author <b>${p.name}</b>`;
					this.startSearch = false;
				});
			},

			onChangedOpt(checked) {
				// eslint-disable-next-line no-console
				//console.log(checked);

				this.search(this.textSearch, checked);
			},

			onSearch() {
				this.search(this.textSearch, this.searchOpt);
			},

			search(text, opt) {
				if (text != undefined && text != '') {
					this.startSearch = true;
					this.items = [];

					this.$http.get(`api/Article/Search/${opt}/${text}`, (res) => {
						this.items = res;
						this.msg = ` with ${opt} have text <b>${text}</b>`;
						this.startSearch = false;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.options {
		margin-top: 30px;
	}
	.results {
		margin: 20px 0 10px 0;
	}
	.item {
		padding: 5px 0;
	}
	.tag1 {
		margin-bottom: 5px;
	}
	.tag2 {
		margin-top: 20px;
		margin-bottom: 5px;
	}
</style>
