<template>
	<div>
		<div class="site-map">PETITIONS</div>
		<div v-if="item">
			<div class="title" v-html="item.title"></div>
			<div class="create-date">{{item.createDate | moment("DD MMM YYYY")}}</div>
			<div class="content" v-html="item.content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PetitionPage',
		watch: {
			$route(to) {
				this.load(to.params.id);
			}
		},
		computed: {
			petitionID() {
				return this.$route.params.id;
			}
		},
		data() {
			return {
				item: null
			}
		},
		mounted() {
			this.$store.commit('setPath', 'PETITIONS');
			this.load(this.petitionID);
		},
		methods: {
			load(id) {
				if (id == undefined || id == null) {
					this.$http.get('api/Petition/GetLast', (res) => {
						this.item = res;
					});
				}
				else {
					this.$http.get(`api/Petition/Get/${id}`, (res) => {
						this.item = res;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.title {
		font-size: 18px;
		font-weight: 700;
	}
	.create-date {
		color: #aaa;
	}
	.content {
		margin-top: 30px;
		font-size: 14px;
	}
</style>
