<template>
	<div id="app">
		<Banner :items="menuItems"></Banner>		
		<div class="content-body">
			<MainMenu :items="toolbarItems" />
			<CategoryMenu />
			<b-container fluid>
				<b-row>
					<b-col cols="8" class="no-left-padding">
						
						<router-view></router-view>
					</b-col>
					<b-col cols="4">
						<div class="right-side">
							<GalleryDisplay />
							<router-view name="right"></router-view>
							<HLine />
							<LastArticles />
							<HLine />
							<ViewestArticles />
							<HLine />
							<YearArchive />
							<HLine />
							<StatisticInfo />
						</div>
					</b-col>
				</b-row>
			</b-container>
			<SiteFooter :tbItems="toolbarItems" :menuItems="menuItems" />
		</div>
	</div>
</template>

<script>
	import Banner from './components/Banner.vue'
	import MainMenu from './components/MainMenu.vue'
	import SiteFooter from './components/SiteFooter.vue'
	//import SiteMap from './components/SiteMap.vue'
	import CategoryMenu from './components/CategoryMenu.vue'
	import LastArticles from './components/LastArticles.vue'
	import ViewestArticles from './components/ViewestArticles.vue'
	import YearArchive from './components/YearArchive.vue'
	import GalleryDisplay from './components/GalleryDisplay.vue'
	import StatisticInfo from './components/StatisticInfo.vue'
	import HLine from './components/HLine.vue'

    export default {
        name: 'app',
        components: {
			Banner,
			MainMenu,
			SiteFooter,
			//SiteMap,
			CategoryMenu,
			LastArticles,
			ViewestArticles,
			YearArchive,
			GalleryDisplay,
			StatisticInfo,
			HLine
		},
		data() {
			return {
				toolbarItems: [
					{ id: 1, name: 'HOME', link: '/' },
					{ id: 9, name: 'SORTING', link: '/sorting' },
					{ id: 2, name: 'INTERVIEWS', link: '/topic/interviews' },
					{ id: 3, name: 'PETITIONS', link: '/topic/petitions' },
					{ id: 4, name: 'DOCUMENTARY VIDEOS', link: '/topic/docvideos/0' },
					{ id: 5, name: 'PROJECTS', link: '/topic/project' },
					{ id: 6, name: 'REFERENCES', link: '/topic/reference' },
					{ id: 7, name: 'AUDIO', link: '/audio' },
					{ id: 8, name: 'DOWNLOADS', link: '/downloads' },
				],
				menuItems: [
					{ id: 1, name: 'Gallery', link: '/topic/gallery' },
					{ id: 2, name: 'Links', link: '/topic/weblinks' },
					{ id: 3, name: 'About', link: '/home/about' }
				]
			}
		}
    }
</script>

<style>
	#app {
		font-family: Segoe UI, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #353535;
		font-size: 14px;
	}
	.content-body2 {
		width: 1024px;
		margin: 0 auto;
	}
	.content-body {
		margin: 0 35px;
	}
	.no-left-padding {
		padding-left: 0 !important;
		padding-right: 20px !important;
		border-right: solid 1px #ddd;
	}
	.right-side {
		padding-left: 10px;
	}
	.site-map {
		font-size: 12px;
		margin-bottom: 10px;
	}
	.side-item-caption {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 10px;
	}

</style>
