<template>
	<div>
		<div class="site-map">INTERVIEWS</div>
		<div>
			<div v-for="p in items" :key="p.id" class="item">
				<div v-if="!p.link">
					<div v-html="p.title" class="note"></div>
				</div>
				<div v-else class="d-flex">
					<div class="photo"><img :src="getImgUrl(p)" alt="pic" class="small" /></div>
					<div class="content">
						<div>
							<a :href="p.link" target="_blank">{{p.title}}</a>
							<span class="create-date"> - {{p.createDate | moment('DD/MM/YYYY')}}</span>
						</div>
						
						<div v-if="p.audio" class="audio">
							<audio controls>
								<source :src="p.audio" type="audio/mpeg">
							</audio>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Constants from '../Contants'

	export default {
		name: 'InterviewPage',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'INTERVIEWS');
			this.load();
		},
		methods: {
			getImgUrl(p) {
				return Constants.API_HOST + `/Public/Images/Interviews/int-${p.id}.jpg`;
			},

			load() {
				this.$http.get('api/Misc/GetInterviewList', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.item {
		padding: 5px 0;
	}
	.content {
		margin-left: 20px;
	}
	.create-date {
		color: #aaa;
	}
	.audio {
		margin-top: 10px;
	}
	.note {
		font-weight: bold;
	}
</style>
