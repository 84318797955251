<template>
	<div>
		<div class="caption">Documentary Videos</div>
		<div class="videos">
			<b-row>
				<b-col cols="4" v-for="p in items" :key="p.id" class="item">
					<div>
						<iframe :src="p.source" frameborder="0" ></iframe>
					</div>
					<router-link :to="`/videos/${p.id}`">
						<div v-html="p.title"></div>
					</router-link>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'VideoSummary',
		components: {
		},
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Misc/GetVideoList/3', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: bold;
		padding-bottom: 5px;
		margin-bottom: 10px;
		border-bottom: solid 1px #ddd;
	}
	.videos {
		margin-top: 10px;
	}
	.item {
		
	}
	iframe {
		width: 100%;
	}
</style>
