import axios from 'axios'
import Constants from '../Contants'

class AxiosEx {
    http = null;

    constructor() {
        this.http = axios.create({
            headers: { 'Content-Type': 'application/json' }
        })
    }

    get(url, callBack) {
        // eslint-disable-next-line no-console
        //console.log('start getting...');

		this.http.get(Constants.BDS_HOST + url)
			.then(res => {
                var data = res.data;

				if (data.hasError) {
					// eslint-disable-next-line no-console
					console.log(url);

					// eslint-disable-next-line no-console
					console.log(data.errorMessages[0]);
				}
				else {
					callBack(data.result);
				}
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error)
            })
    }

    download(url, callBack) {
        // eslint-disable-next-line no-console
        //console.log('start downloading...');

        axios({
			url: Constants.BDS_HOST + url,
            method: 'GET',
            responseType: 'blob',
        })
            .then(res => {
                callBack(res.data);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error)
            })
    }
}

export default new AxiosEx();
