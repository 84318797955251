<template>
	<div>
		<div class="side-item-caption">Gallery</div>
		<div class="list">
			<div v-for="(p, index) in items" :key="index" class="item">
				<img :src="getImgUrl(p)" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
	import Constants from '../Contants'

	export default {
		name: 'GalleryDisplay',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.load();
		},
		methods: {
			getImgUrl(p) {
				return Constants.API_HOST + `/Public/Images/FotoGallery/${p.folder}/${p.fileName}`;
			},

			load() {
				this.$http.get('api/Misc/GetGalleryPhotos/0', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.list {
		float: none;
		overflow: auto;
	}
	.item {
		float: left;
		width: 33%;
	}
		.item img {
			width: 100%;
			height: 100px;
			padding-right: 5px;
			padding-bottom: 5px;
		}
</style>

