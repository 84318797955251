<template>
	<div>
		<div class="site-map">AUTHOR</div>
		<div v-if="item">
			<div class="name">
				{{item.author.name}}
				<img :src="getImgUrl(item.author.userID)" @error="onImgError($event)" />
			</div>
			<div class="desc" v-if="item.author.desc" v-html="item.author.desc"></div>
			<div class="articles">
				<ol>
					<li v-for="p in item.articles" :key="p.itemID" class="item">
						<router-link :to="`/article/article/${p.itemID}`">{{p.title}}</router-link>
					</li>
				</ol>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AuthorPage',
		watch: {
			$route(to, ) {
				this.load(to.params.id);
			}
		},
		computed: {
			authorID() {
				return this.$route.params.id;
			}
		},
		data() {
			return {
				item: null,
			}
		},
		mounted() {
			this.$store.commit('setPath', 'AUTHORS');
			this.load(this.authorID);
		},
		methods: {
			load(id) {
				this.$http.get(`api/Author/GetAuthorAndArticles/${id}`, (res) => {
					this.item = res;
				});
			},

			onImgError(event) {
				event.target.src = '/Images/Personal/non-author.png';
			},

			getImgUrl(id) {
				return `/Images/Personal/au${id}.jpg`;
			}
		}
	}
</script>

<style scoped>
	.name {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 10px;
	}
	.desc {
		margin-bottom: 10px;
	}
	.item {
		margin-bottom: 10px;
	}
	.name img {
		width: 30px;
		height: 30px;
		border-radius: 20px;
		margin-left: 10px;
		margin-bottom: 5px;
	}
</style>
