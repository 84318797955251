<template>
	<div>
		<div class="side-item-caption">Latest items</div>
		<ol>
			<li v-for="p in items" :key="p.itemID" class="item">
				<router-link :to="`/article/article/${p.itemID}`">{{p.title}}</router-link>
			</li>
		</ol>
	</div>
</template>

<script>
	export default {
		name: 'LastArticles',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Article/GetHomePage', (res) => {
					this.items = res.items;
				});
			},

			getImage(id) {
				return `/images/articles/small/a${id}.jpg`;
			}
		}
	}
</script>

<style scoped>
	ol {
		list-style: none;
		counter-reset: item;
		margin: 0;
		padding-left: 35px;
	}
	li {
		counter-increment: item;
		margin-bottom: 5px;
		position: relative;
	}
li:before {
		top: 0;
		left: -35px;
		position: absolute;
		margin-right: 10px;
		content: counter(item);
		background: #02913F;
		border-radius: 100%;
		color: white;
		width: 22px;
		height: 22px;
		text-align: center;
		display: inline-block;
	}
	.item {
		padding-bottom: 5px;
	}
</style>

