<template>
	<div class="line">
		<div></div>
	</div>
</template>

<script>
	export default {
		name: 'HLine',
	}
</script>

<style scoped>
	.line {
		padding-top: 10px;
		padding-bottom: 10px;
	}
		.line div {
			border-bottom: solid 1px #ddd;
		}
</style>