<template>
    <div class="statistic-info">
        <center>
        <div>
            <!-- BEGIN: Powered by Supercounters.com -->
            <script type="application/javascript" src="http://widget.supercounters.com/map.js"></script>
            <script type="application/javascript">var sc_map_var = sc_map_var || []; sc_map(342637, "112288", "ff0000", 43)</script>
            <br>
            <noscript><a href="http://www.supercounters.com/">Visitor Map Widget</a></noscript>
            <!-- END: Powered by Supercounters.com -->
        </div>

        <div>
            <!-- BEGIN: Powered by Supercounters.com -->
            <script type="application/javascript" src="http://widget.supercounters.com/online_t.js"></script>
            <script type="application/javascript" >sc_online_t(342650, "Users Online", "121212");</script>
            <br>
            <noscript><a href="http://www.supercounters.com/">Free Tumblr Users Online Counter</a></noscript>
            <!-- END: Powered by Supercounters.com -->
        </div>
        <br />
        <div>
            <div class="hit">Hit Counter</div>
            <div>
                <!-- BEGIN: Powered by Supercounters.com -->
                <script type="application/javascript" src="http://widget.supercounters.com/hit.js"></script>
                <script type="application/javascript">sc_hit(342633, 4, 9);</script>
                <br>
                <noscript><a href="http://www.supercounters.com/">free Hit Counter</a></noscript>
                <!-- END: Powered by Supercounters.com -->
            </div>

        </div>
        <br />
        </center>
    </div>
</template>

<script>
    export default {
        name: 'StatisticInfo',
        mounted() {
            //this.addScript("http://widget.supercounters.com/map.js");
            //this.addScript("http://widget.supercounters.com/online_t.js");
            //this.addScript("http://widget.supercounters.com/hit.js");
        },
        methods: {
            addScript(src) {
                let jsNode = document.createElement('script');    
                jsNode.setAttribute('src', src);
                document.head.appendChild(jsNode);
            }
        }
    }
</script>

<style scoped>
    .statistic-info {
        margin-top: 10px;
    }
    .hit {
        color:#121212;
        margin-bottom: 5px;
    }
</style>

