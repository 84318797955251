<template>
	<div class="cat-menu" v-if="items.length > 0">
		<b-container fluid>
			<b-row>
				<b-col cols="9" class="no-left-padding">
					<div class="env">
						<div v-for="p in envItems" :key="p.categoryID" class="env-item">
							<router-link :to="`/article/group/${p.categoryID}/e/${p.name}`">{{p.name}}</router-link> 
							-<span class="comment">{{p.comment}}</span>
						</div>
					</div>
				</b-col>
				<b-col cols="3">
					<div class="right-side">
						<ul>
							<li v-for="p in justiceItems" :key="p.categoryID">
								<router-link :to="`/article/group/${p.categoryID}/j/${p.name}`">{{p.name}}</router-link>
							</li>
						</ul>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
    export default {
		name: 'EnvCategories',
		computed: {
			envItems() {
				return this.items.filter(p => p.type == 0);
			},
			justiceItems() {
				return this.items.filter(p => p.type == 1);
			}
		},
		data() {
			return {
				items: []
			}
		},
        mounted() {
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Category/GetList/-1', (res) => {
					this.items = res;
				});
			}
		}
	}
</script>

<style scoped>
	.cat-menu {
		padding-bottom: 5px;
		margin-bottom: 10px;
		margin-top: 5px;
		border-bottom: solid 1px #ddd;
	}
	.no-left-padding {
		padding-left: 0 !important;
		padding-right: 10px !important;
		border-right: solid 1px #ddd;
	}
	.right-side ul{
		padding-left: 10px;
	}
	.right-side li {
		padding-bottom: 5px;
	}
	.env {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 15px;
		-moz-column-gap: 15px;
		column-gap: 15px;
	}
	.env-item {
		display: inline-block;
		width: 100%;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		padding-bottom: 3px;
	}
	.comment {
		padding-left: 5px;
		color: #888;
	}
	a:link,
	a:visited,
	a:active,
	a:hover {
		color: #02913F;
	}
</style>
