<template>
	<div>
		<div class="site-map">WEB LINKS</div>
		<ol>
			<li v-for="p in items" :key="p.linkID" class="item">
				<a :href="p.url" target="_blank">{{p.name}}</a>
			</li>
		</ol>
	</div>
</template>

<script>
	export default {
		name: 'LinkPage',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'LINKS');
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Misc/GetLinkList', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.item {
		padding: 5px 0;
	}
</style>
