<template>
	<div class="viewest">
		<div class="side-item-caption">Viewest items</div>
		<ol>
			<li v-for="p in items" :key="p.itemID" class="item">
				<router-link :to="`/article/article/${p.itemID}`">{{p.title}}</router-link>
			</li>
		</ol>
	</div>
</template>

<script>
	export default {
		name: 'ViewestArticles',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Article/GetViewestArticles/5', (res) => {
					this.items = res;
				});
			}
		}
	}
</script>

<style scoped>
	.caption {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	.viewest ol {
		list-style: none;
		counter-reset: item;
		margin: 0;
		padding-left: 35px;
	}
	.viewest li {
		counter-increment: item;
		margin-bottom: 5px;
		position: relative;
	}
		.viewest li:before {
			top: 0;
			left: -35px;
			position: absolute;
			margin-right: 10px;
			content: counter(item);
			background: #02913F;
			border-radius: 100%;
			color: white;
			width: 22px;
			height: 22px;
			text-align: center;
			display: inline-block;
		}
	.viewest .item {
		padding-bottom: 5px;
	}
</style>