var funcMixin = {
    methods: {
        toLowTitle2(str) {
            str = str.toLowerCase();
            return str.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
        }
    }
}

export default funcMixin;

