<template>
    <div class="main" v-if="items.length > 0">
        <b-container fluid>
            <b-row>
                <b-col cols="7" class="left-column">
                    <div class="first-item" :set="p = items[0]">
                        <div><img :src="getImgUrl(p)" alt="pic" /></div>
                        <div class="title">
                            <router-link :to="`/article/article/${p.itemID}`">{{p.title}}</router-link>
                        </div>
                        <div class="intro">
                            {{p.introduction}}
                            <div class="create-date">
                                {{p.createDate | moment("MMM DD, YYYY")}} 
                                <span v-if="p.authorID">| by {{p.authorName}}</span>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="5">
                    <div class="others">
                        <div v-for="p in others" :key="p.itemID" class="item">
                            <div class="title">
                                <router-link :to="`/article/article/${p.itemID}`">{{p.title}}</router-link>
                            </div>
                            <div class="intro">
                                {{p.introduction}}
                                <div class="create-date">
                                    {{p.createDate | moment("MMM DD, YYYY")}}
                                    <span v-if="p.authorID">| by {{p.authorName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import Constants from '../Contants'

    export default {
        name: 'HomeItems',
        data() {
			return {
				items: [],
			}
        },
        computed: {
            others() {
				return this.items.slice(1);
			},
        },
        mounted() {
			this.load();
		},
		methods: {
            load() {
                this.$http.get('api/Article/GetLastArticles/3', (res) => {
					this.items = res;
                });
            },

            getImgUrl(p) {
				return Constants.API_HOST + `/Public/Images/Articles/big/b${p.itemID}.jpg`;
            },
            
			getAuthorImg(id) {
				return `/Images/Personal/au${id}.jpg`;
            },
            
            onImgError(event) {
				event.target.src = '/Images/Personal/non-author.png';
			},

        }
    }
</script>

<style scoped>
    .col-5, .col-6, .col-7 {
        padding-left: 0;
        padding-right: 0;
    }
    .main {
        margin-bottom: 20px;
    }
    .left-column {
        padding-left: 0;
        
    }
    .title {
        font-size: 14px;
        font-weight: 700;
        font-family: Georgia, Times New Roman, Segoe UI;
    }
    .first-item img {
        width: 100%;
    }
    .first-item .title {
		font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        
    }
    .item:not(:last-child) {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: solid 1px #ddd;
    }
    .item .title {
        margin-bottom: 10px;
    }
    .others {
        margin-left: 15px;
        padding-left: 15px;
        border-left: solid 1px #ddd;
        height: 100%;
    }
	.create-date {
		padding-top: 20px;
		color: #666;
		font-size: 11px;
    }
    .create-date img {
		width: 30px;
		height: 30px;
		border-radius: 20px;
		margin-left: 10px;
		margin-bottom: 5px;
	}
</style>

