import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "./store";
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import AxiosEx from './services/AxiosEx'
import HomePage from './pages/HomePage.vue'
import CategoryPage from './pages/CategoryPage.vue'
import AuthorPage from './pages/AuthorPage.vue'
import ArticlePage from './pages/ArticlePage.vue'
import PetitionPage from './pages/PetitionPage.vue'
//import PetitionLayout from './pages/PetitionLayout.vue'
import ReferencePage from './pages/ReferencePage.vue'
import DownloadPage from './pages/DownloadPage.vue'
import ProjectPage from './pages/ProjectPage.vue'
import VideoPage from './pages/VideoPage.vue'
import AudioPage from './pages/AudioPage.vue'
import LinkPage from './pages/LinkPage.vue'
import InterviewPage from './pages/InterviewPage.vue'
import ArchivePage from './pages/ArchivePage.vue'
import GalleryPage from './pages/GalleryPage.vue'
import AboutPage from './pages/AboutPage.vue'
import SearchPage from './pages/SearchPage.vue'
import SortingPage from './pages/SortingPage.vue'
import PageNotFound from './pages/PageNotFound.vue'

import HomePageSide from './components/HomePageSide.vue'
import ArticleSide from './components/ArticleSide.vue'
import PetitionList from './components/PetitionList.vue'
import AuthorList from './components/AuthorList.vue'
import ProjectList from './components/ProjectList.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.css'

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(require('vue-moment'));
Vue.config.productionTip = false

Vue.$http = AxiosEx;

Object.defineProperty(Vue.prototype, '$http', {
    get() {
        return AxiosEx;
    }
})

//global.toLowTitle = (str) => {
//    return str.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
//}

const routes = [
    {
        path: "/", components: {
            default: HomePage,
            right: HomePageSide
        }
    },
    {
        path: "/article/group/:id/:type/:name", components: {
            default: CategoryPage,
            right: HomePageSide
        }
    },
    {
        path: "/article/author/:id", components: {
            default: AuthorPage,
            right: AuthorList
        }
    },
    {
        path: "/article/article/:id", components: {
            default: ArticlePage,
            right: ArticleSide
        }
    },
    {
        path: "/topic/petition/:id", components: {
            default: PetitionPage,
            right: PetitionList
        }
    },
    {
        path: "/topic/petitions", components: {
            default: PetitionPage,
            right: PetitionList
        }
    },
    {
        path: "/topic/reference", components: {
            default: ReferencePage,

        }
    },
    {
        path: "/topic/interviews", components: {
            default: InterviewPage,

        }
    },
    {
        path: "/downloads", components: {
            default: DownloadPage,

        }
    },
    {
        path: "/topic/project/:id", components: {
            default: ProjectPage,
            right: ProjectList

        }
    },
    {
        path: "/topic/project", components: {
            default: ProjectPage,
            right: ProjectList

        }
    },
    {
        path: "/topic/docvideos/:id", components: {
            default: VideoPage,

        }
    },
    {
        path: "/audio", components: {
            default: AudioPage,

        }
    },
    {
        path: "/home/about", components: {
            default: AboutPage,

        }
    },
    {
        path: "/topic/weblinks", components: {
            default: LinkPage,

        }
    },
    {
        path: "/archive/:year", components: {
            default: ArchivePage,

        }
    },
    {
        path: "/topic/gallery", components: {
            default: GalleryPage,
        }
    },
    {
        path: "/search", components: {
            default: SearchPage,
        }
    },
    {
        path: "/sorting", components: {
            default: SortingPage,
        }
    },
    {
        path: "/PageNotFound", components: {
            default: PageNotFound,
        }
    },
    {
        path: "*", components: {
            default: PageNotFound,
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes
});

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')


//http://vietecology.org/Article/Article/3409
//http://vietecology.org/Article/Group/1
//http://vietecology.org/Topic/Interviews
//http://vietecology.org/Topic/Petitions
//http://vietecology.org/Topic/DocVideos
//http://vietecology.org/Topic/Project
//http://vietecology.org/Search
//http://vietecology.org/Topic/Reference  --audio, download, 
//http://vietecology.org/Download/Download/36
//http://vietecology.org/Download/1A%20Van%20Nam%20MKDSNM.mp3
//http://vietecology.org/Topic/Gallery
//http://vietecology.org/Topic/WebLinks
//http://vietecology.org/Home/About
