<template>
	<div class="main-menu d-flex">
		<div>
			<b-nav>
				<b-nav-item v-for="p in items" :key="p.id" :to="p.link">{{p.name}}</b-nav-item>
			</b-nav>
		</div>
		<div class="ml-auto">
			<b-nav>
				<b-nav-item to="/search">
					<b-icon-search></b-icon-search>
					<span class="search">SEARCH</span>
				</b-nav-item>
			</b-nav>
		</div>
	</div>
</template>

<script>
	import { BIconSearch } from 'bootstrap-vue'

    export default {
		name: 'MainMenu',
		components: {
			BIconSearch
		},
		props: [
			'items'
		],
	}
</script>

<style scoped>
	.main-menu {
		/*margin-top: 10px;
		border-top: solid 1px #eee;*/
		margin-top: 5px;
		border-bottom: solid 1px #aaa;
		text-align: center; 
		align-items: center;
	}
		.main-menu a.nav-link {
			color: #121212;
			font-size: 11px;
		}
	.search {
		margin-left: 10px;
	}
</style>
