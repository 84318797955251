<template>
	<div>
		<div class="caption">Project List</div>
		<ol>
			<li v-for="p in items" :key="p.projectID">
				<router-link :to="`/topic/project/${p.projectID}`">
					<div v-html="p.name"></div>
				</router-link>
			</li>
		</ol>
	</div>
</template>

<script>
	export default {
		name: 'ProjectList',
		components: {
		},
		data() {
			return {
				items: []
			}
		},

		mounted() {
			this.load();
		},

		methods: {
			load() {
				this.$http.get('api/Misc/GetProjectBrefList', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: 700;
		margin-bottom: 10px;
	}

	ol {
		list-style: none;
		counter-reset: item;
		margin: 0;
		padding-left: 35px;
	}

	li {
		counter-increment: item;
		margin-bottom: 10px;
		position: relative;
	}

		li:before {
			top: 0;
			left: -35px;
			position: absolute;
			margin-right: 10px;
			content: counter(item);
			/* background: #F57B06;*/
			background: #02913F;
			border-radius: 100%;
			color: white;
			width: 22px;
			height: 22px;
			text-align: center;
			display: inline-block;
		}
</style>
