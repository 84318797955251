<template>
	<div>
		<div class="side-item-caption">Author List</div>
		<div v-for="p in items" :key="p.id" class="item">
			<div class="photo">
				<img :src="getImgUrl(p.id)" @error="onImgError($event)" />
			</div>
			<div><router-link :to="`/article/author/${p.id}`" exact>{{p.name}}</router-link> ({{p.count}})</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AuthorList',
		data() {
			return {
				items: [],
				imgError: false,
			}
		},
		computed: {
			isShow() {
				return !this.imgError;
			}
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				// eslint-disable-next-line no-console
				//console.log('Loading authors...');

				this.$http.get('api/Author/GetAuthorExList', (res) => {
					this.items = res;
				});
			},
			onImgError(event) {
				//this.imgError = true;
				//this.isShowImg = false;
				event.target.src = '/Images/Personal/non-author.png';
			},
			getImgUrl(id) {
				return `/Images/Personal/au${id}.jpg`;
			}
		}
	}
</script>

<style scoped>
	.item {
		display: flex;
		flex-direction: row;
		padding-bottom: 5px;
	}
		.item .photo {
			padding-right: 10px;
		}
		.item img {
			width: 30px;
			height: 30px;
		}
</style>

