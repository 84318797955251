<template>
	<div class="block">
		<div class="caption">Petitions</div>
		<div class="petitions">
			<div v-for="p in items" :key="p.id" class="item">
				<router-link :to="`/petition/${p.id}`">
					<div v-html="p.title"></div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PetitionList',
		components: {
		},
		data() {
			return {
				items: []
			}
		},

		mounted() {
			this.load();
		},

		methods: {
			load() {
				this.$http.get('api/Petition/GetPetitionBrefList', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.block {
		margin-top: 20px;
	}
	.caption {
		font-weight: bold;
		padding-bottom: 5px;
		margin-bottom: 10px;
		border-bottom: solid 1px #ddd;
	}
	.petitions {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 10px;
		-moz-column-gap: 10px;
		column-gap: 15px;
		column-rule: 1px solid #aaa;
	}
	.item {
		display: inline-block;
		width: 100%;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		padding-bottom: 10px;
	}
</style>
