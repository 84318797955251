<template>
	<div class="year-archive">
		<div class="caption">Archive</div>
		<div class="list">
			<div v-for="p in items" :key="p.year" class="item">
				<router-link :to="`/archive/${p.year}`">
					<div>{{p.year}} ({{p.count}})</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'YearArchive',
		components: {
		},
		data() {
			return {
				items: []
			}
		},

		mounted() {
			this.load();
		},

		methods: {
			load() {
				this.$http.get('api/Article/GetArticleAchive', (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 5px;
	}
	.list {
		float: none;
		overflow: auto;
	}
	.item {
		float: left;
		padding: 0 10px 5px;
	}
</style>

