const Constants = {
	//API_HOST: 'http://localhost:55258/',
	//BDS_HOST: 'http://localhost:55258/',

	API_HOST: 'http://vietecology.org/',
	//API_HOST: 'http://test.vietecology.org/',  //test only
	BDS_HOST: 'http://bds.vietecology.org/',
	
};

export default Constants;
