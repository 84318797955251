<template>
	<div>
		<div class="caption">Archive {{year}}</div>
		<ol>
			<li v-for="p in items" :key="p.itemID" class="item">
				<router-link :to="`/article/article/${p.itemID}`"><div v-html="p.title"></div></router-link>
			</li>
		</ol>
	</div>
</template>

<script>
	export default {
		name: 'ArchivePage',
		watch: {
			$route(to) {
				this.load(to.params.year);
			}
		},
		computed: {
			year() {
				return this.$route.params.year;
			}
		},
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'ARCHIVE');
			this.load(this.year);
		},
		methods: {
			load(year) {
				this.$http.get(`api/Article/GetArchiveList/${year}`, (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.item {
		padding: 5px 0;
	}
</style>
