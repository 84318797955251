<template>
	<div>
		<div class="d-flex">
			<div class="mr-auto"><SiteMap /></div>
			<div v-if="page">
				<ItemPaging :page="page" @onMoveBack="onMoveBack" @onMoveNext="onMoveNext"></ItemPaging>
			</div>
		</div>
		<div class="bref-items" v-if="page">
			<div v-for="p in page.items" :key="p.itemID" class="bref-item-ext">
				<div class="bref-item">
					<div class="title">
						<router-link :to="`/article/article/${p.itemID}`"><span v-html="p.title"></span></router-link>
					</div>
					<div class="intro">
						<img :src="getImgUrl(p)" alt="pic" class="small" />
						{{p.introduction}}
						<div class="create-date">
							{{p.createDate | moment("MMM DD, YYYY")}}
							<span v-if="p.authorID"> | by {{p.authorName}}</span>
						</div>
					</div>
				</div>
			</div>

		</div>
		<PetitionSummary />
	</div>
</template>

<script>
	import Constants from '../Contants'
	import SiteMap from '../components/SiteMap.vue'
	import ItemPaging from '../components/ItemPaging.vue'
	import PetitionSummary from '../components/PetitionSummary.vue'

	export default {
		name: 'CategoryPage',
		components: {
			SiteMap,
			ItemPaging,
			PetitionSummary
		},
		watch: {
			$route(to) {
				this.setSiteMap(to.params.type, to.params.name);
				this.load(to.params.id);
			}
		},
		computed: {
			categoryID() {
				return this.$route.params.id;
			}
		},
		data() {
			return {
				id: 0,
				page: null,
			}
		},
		mounted() {
			this.setSiteMap(this.$route.params.type, this.$route.params.name);
			this.load(this.categoryID);
		},
		methods: {
			setSiteMap(type, name) {
				this.$store.commit('setPath', type == 'e' ? 'ENVIRONMENT' : 'JUSTICE');
				this.$store.commit('addPath', name);
			},

			getImgUrl(p) {
				return Constants.API_HOST + `/Public/Images/Articles/small/a${p.itemID}.jpg`;
			},

			load(id) {
				this.id = id;
				//this.$http.get(`api/Article/GetItemsOfCategory/${id}`, (res) => {
				//	this.items = res;
				//});

				this.$http.get(`api/Article/GetPageByCategory/${id}/8`, (res) => {
					this.page = res;
				});
			},

			onMoveBack() {
				var page = this.page;
				this.$http.get(`api/Article/GetBackByCategory/${this.id}/${page.itemCount}/${page.firstItem}/${page.lastItem}`, (res) => {
					this.page = res;
				});
			},

			onMoveNext() {
				var page = this.page;
				this.$http.get(`api/Article/GetNextByCategory/${this.id}/${page.itemCount}/${page.firstItem}/${page.lastItem}`, (res) => {
					this.page = res;
				});
			}
		}
	}
</script>

<style scoped>
	.bref-items {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
		-webkit-column-gap: 30px;
		-moz-column-gap: 30px;
		column-gap: 30px;
	}

	.bref-item-ext {
		display: inline-block;
		width: 100%;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	.bref-item {
		margin-bottom: 30px;
	}

		.bref-item .intro {
			margin-top: 5px;
		}

		.bref-item img.small {
			float: left;
			width: 128px;
			margin-top: 6px;
			padding-right: 15px;
			padding-bottom: 10px;
		}

	.title a {
		font-size: 14px;
		font-weight: 700;
		font-family: Georgia, Times New Roman, Segoe UI;
	}
	.create-date {
		padding-top: 20px;
		color: #555;
		font-size: 11px;
	}
</style>

