<template>
	<div>
		<HomeItems />
		<CategorySummary />
		<VideoSummary />
		<PetitionSummary />
		<ProjectSummary />
	</div>
</template>

<script>
	//import LastItem from '../components/LastItem.vue'
	import HomeItems from '../components/HomeItems.vue'
	import CategorySummary from '../components/CategorySummary.vue'
	import PetitionSummary from '../components/PetitionSummary.vue'
	import VideoSummary from '../components/VideoSummary.vue'
	import ProjectSummary from '../components/ProjectSummary.vue'

	export default {
		name: 'HomePage',
		components: {
			//LastItem,
			HomeItems,
			CategorySummary,
			PetitionSummary,
			VideoSummary,
			ProjectSummary
		},
		mounted() {
			this.$store.commit('setPath', 'HOME');
		}
	}
</script>

<style scoped>
	a:link,
	a:visited,
	a:active,
	a:hover {
		color: #02913F;
	}
</style>

