<template>
	<div>
		<div class="site-footer">
			<b-container fluid>
				<b-row>
					<b-col cols="8" class="no-left-padding">
						<div class="d-flex">
							<div class="menu-column" v-if="items">
								<div class="title">ENVIRONMENT</div>
								<div v-for="p in envItems" :key="p.categoryID" class="item">
									<router-link :to="`/category/${p.categoryID}/e/${p.name}`">{{p.name}}</router-link>
								</div>
							</div>
							<div class="menu-column" v-if="items">
								<div class="title">JUSTICE</div>
								<div v-for="p in justiceItems" :key="p.categoryID" class="item">
									<router-link :to="`/category/${p.categoryID}/j/${p.name}`">{{p.name}}</router-link>
								</div>
							</div>
							<div class="menu-column">
								<div v-for="(p, index) in tbItems" :key="index" class="item">
									<router-link :to="p.link">{{p.name}}</router-link>
								</div>
							</div>
							<div class="menu-column">
								<div v-for="(p, index) in menuItems" :key="index" class="item">
									<router-link :to="p.link">{{p.name}}</router-link>
								</div>
							</div>
						</div>
					</b-col>
					<b-col cols="4">
						<div>
							<b>Email Address:</b><br />vefmedia@vietecology.org
						</div>
						<br />
						<div>
							<b>TIN:</b><br />US Non-profit NGO, 51-0505827
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="copyright">&copy; VietEcology 2003 - 2019 Viet Ecology Foundation. All rights reserved.</div>
	</div>
</template>

<script>
	export default {
		name: 'SiteFooter',
		props: [
			'tbItems',
			'menuItems'
		],
		data() {
			return {
				items: null,
			}
		},
		computed: {
			envItems() {
				return this.items.filter((p) => p.type == 0);
			},
			justiceItems() {
				return this.items.filter((p) => p.type == 1);
			}
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Category/GetList/-1', (res) => {
					this.items = res;
				});
			}
		}
	}
</script>

<style scoped>
	.site-footer {
		margin-top: 10px;
		margin-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-top: double 5px #e5e5e5;
		border-bottom: solid 1px #e5e5e5;
	}
	.no-left-padding {
		padding-left: 0 !important;
		border-right: solid 1px #ddd;
	}
	.menu-column {
		margin-right: 50px;
	}
	.copyright {
		margin-top: 10px;
	}
	.title {
		font-weight: 700;
		margin-bottom: 5px;
	}
	.item {
		margin-bottom: 5px;
	}
</style>

