<template>
	<div class="thesame-category">
		<div class="caption">The same category</div>
		<ol>
			<li v-for="p in items" :key="p.itemID">
				<router-link :to="`/article/article/${p.itemID}`">
					<div v-html="p.title"></div>
				</router-link>
			</li>
		</ol>
	</div>
</template>

<script>
	export default {
		name: 'TheSameCategory',
		computed: {
			articleID() {
				return this.$route.params.id;
			}
		},
		data() {
			return {
				items: []
			}
		},

		mounted() {
			this.load(this.articleID);
		},

		methods: {
			load(id) {
				this.$http.get(`api/Article/GetTheSameCategory/${id}`, (res) => {
					this.items = res;
				});
			},
		}
	}
</script>

<style scoped>
	.caption {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 10px;
		
	}
	ol {
		list-style: none;
		counter-reset: item;
		margin: 0;
		padding-left: 35px;
	}

	li {
		counter-increment: item;
		margin-bottom: 5px;
		position: relative;
	}

		li:before {
			top: 0;
			left: -35px;
			position: absolute;
			margin-right: 10px;
			content: counter(item);
			background: #02913F;
			border-radius: 100%;
			color: white;
			width: 22px;
			height: 22px;
			text-align: center;
			display: inline-block;
		}

	.item {
		padding-bottom: 5px;
	}
</style>
