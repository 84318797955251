<template>
    <div>
		<div class="d-flex">
			<div><SiteMap /></div>
            <div class="ml-4 mr-auto">
                <b-form-group  v-slot="{ ariaDescribedby }">
                    <b-form-radio-group v-model="sortType"
                                        :options="options"
                                        :aria-describedby="ariaDescribedby"
                                        name="radio-inline"
                                        class="options"
                                        @change="onChangedOpt">
                    </b-form-radio-group>
                </b-form-group>	
            </div>
			<div v-if="page">
				<ItemPaging :page="page" @onMoveBack="onMoveBack" @onMoveNext="onMoveNext"></ItemPaging>
			</div>
		</div>
		<div class="bref-items" v-if="page">
			<div v-for="p in page.items" :key="p.itemID" class="bref-item-ext">
				<div class="bref-item">
					<div class="title">
						<router-link :to="`/article/article/${p.itemID}`"><span v-html="p.title"></span></router-link>
					</div>
					<div class="intro">
						<img :src="getImgUrl(p)" alt="pic" class="small" />
						{{p.introduction}}
						<div class="d-flex">
							<div class="date mr-auto">
								<span>{{p.createDate | moment('MMM DD, YYYY')}}</span>
								<span v-if="p.authorID"> | by {{p.authorName}}</span>
							</div>
							<div class="views" v-if="sortType == 1">Views: {{p.view.toLocaleString()}}</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
    import Constants from '../Contants'
	import SiteMap from '../components/SiteMap.vue'
    import ItemPaging from '../components/ItemPaging.vue'
    
    export default {
        name: 'SortingPage',
        components: {
			SiteMap,
			ItemPaging,
        },
        data() {
			return {
				sortType: 0,
                page: null,
                options: [
					{ text: 'By Time', value: 0 },
					{ text: 'By View', value: 1 },
				]
			}
		},
		mounted() {
			this.$store.commit('setPath', 'SORTING');
			this.load(this.sortType);
		},
		methods: {
			getImgUrl(p) {
				return Constants.API_HOST + `/Public/Images/Articles/small/a${p.itemID}.jpg`;
			},

			load(sortType) {
				this.$http.get(`api/Article/GetSortingPage/${sortType}/8`, (res) => {
					this.page = res;
				});
            },
            
            onChangedOpt(sortType) {
				// eslint-disable-next-line no-console
                console.log(sortType);
                
                this.sortType = sortType;
				this.load(sortType);
			},

			onMoveBack() {
				var page = this.page;
				this.$http.get(`api/Article/GetSortingPageOnBack/${this.sortType}/${page.itemCount}/${page.firstItem}/${page.lastItem}`, (res) => {
					this.page = res;
				});
			},

			onMoveNext() {
				var page = this.page;
				this.$http.get(`api/Article/GetSortingPageOnNext/${this.sortType}/${page.itemCount}/${page.firstItem}/${page.lastItem}`, (res) => {
					this.page = res;
				});
			},

			onImgError(event) {
				event.target.src = '/Images/Personal/non-author.png';
			},

			getAuthorImg(id) {
				return `/Images/Personal/au${id}.jpg`;
			}
		}
    }
</script>

<style scoped>
    .bref-items {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
		-webkit-column-gap: 30px;
		-moz-column-gap: 30px;
		column-gap: 30px;
	}

	.bref-item-ext {
		display: inline-block;
		width: 100%;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	.bref-item {
		margin-bottom: 30px;
	}

		.bref-item .intro {
			margin-top: 5px;
		}

		.bref-item img.small {
			float: left;
			width: 128px;
			margin-top: 6px;
			padding-right: 15px;
			padding-bottom: 10px;
		}

	.title a {
		font-size: 14px;
		font-weight: bold;
		font-family: georgia, Times New Roman, Segoe UI;
	}
    .views {
        margin-top: 15px;
		font-size: 11px;
        color: #02913F;
    }
	.date {
		margin-top: 15px;
		font-size: 11px;
        color: #555;
	}
	.date img {
		width: 30px;
		height: 30px;
		border-radius: 20px;
		margin-left: 5px;
		margin-bottom: 15px;
	}	
</style>

