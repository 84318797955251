<template>
	<div class="project-summary" v-if="items.length > 0">
		<div class="caption">Projects</div>
		<b-row>
			<b-col cols="8" class="separate-right">
				<div class="d-flex" :set="p = items[0]">
					<div class="photo-1"><img :src="`/Images/Projects/proj${p.projectID}.jpg`" alt="pic" /></div>
					<div class="content-1">
						<div class="title">
							<router-link :to="`/project/${p.projectID}`" class="item">{{p.name}}</router-link>
						</div>
						<div class="intro">{{p.intro}}</div>
					</div>
				</div>
			</b-col>
			<b-col cols="4" class="list">
				<ul>
					<li v-for="p in remainList" :key="p.projectID">
						<router-link :to="`/project/${p.projectID}`" class="item">{{p.name}}</router-link>
					</li>
				</ul>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	export default {
		name: 'ProjectSummary',
		data() {
			return {
				items: []
			}
		},
		computed: {
			remainList() {
				return this.items.slice(1);
			}
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Misc/GetProjectList', (res) => {
					this.items = res;
				});
			}
		}
	}
</script>

<style scoped>
	.project-summary {
		margin-top: 20px;
	}
	.caption {
		font-weight: bold;
		padding-bottom: 5px;
		margin-bottom: 10px;
		border-bottom: solid 1px #ddd;
	}
	.separate-right {
		border-right: solid 1px #ddd;
	}
	.photo-1 {
		width: 50%;
		padding-right: 10px;
		margin-right: 10px;
	}
	.content-1 {
		width: 50%;
	}
	.photo-1 img {
		width: 100%;
	}
	.title {
		font-weight: 700;
		margin-bottom: 10px;
	}
	.list ul {
		padding-left: 20px;
	}
	.item {
		padding-bottom: 5px;
	}
</style>
