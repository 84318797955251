<template>
    <div class="d-flex">
        <div>
            <a href='#' @click.prevent='onLike' :title="title4Like">
                <b-icon-hand-thumbs-up :variant="style4Like" class='up icon'></b-icon-hand-thumbs-up>
            </a>
            <span>{{likeCount}} Like</span>
        </div>

        <div class="ml-3">
            <a href='#' @click.prevent='onDislike' :title="title4Dislike">
                <b-icon-hand-thumbs-down :variant="style4Dislike" class='down icon'></b-icon-hand-thumbs-down>
            </a>
            <span class="dislike">{{dislikeCount}} Dislike</span>
        </div>
    </div>
</template>

<script>
    import { BIconHandThumbsUp, BIconHandThumbsDown } from 'bootstrap-vue'

    export default {
        name: 'LikeIcon',
        components: {
            BIconHandThumbsUp,
            BIconHandThumbsDown,
        },
        props: [
            'item',
        ],
        watch: {
            item: function() {
                this.likeState = null;
                this.likeCount = this.item.like;
                this.dislikeCount = this.item.dislike;
                this.articleID = this.item.itemID;
                this.checkLike();
            },
        },
        data() {
            return {
                likeState: null, //1: like, 0: dislike, null: nothing
                likeCount: 0,
                dislikeCount: 0,
                articleID: 0,
            }
        },
        computed: {
            title4Like() { 
                return (this.likeState == 1) ? 'Unlike this' : 'I like this';
            },

            title4Dislike() { 
                return (this.likeState == 0) ? 'Undislike this' : 'I dislike this';
            },

            style4Like() { 
                return (this.likeState == 1) ? 'success' : 'secondary';
            },

            style4Dislike() { 
                return (this.likeState == 0) ? 'success' : 'secondary';
            }
        },
        mounted() {
            this.articleID = this.item.itemID;            
            this.likeCount = this.item.like;
            this.dislikeCount = this.item.dislike;

            // eslint-disable-next-line no-console
            //console.log(this.articleID);
            
            this.checkLike();
        },
        methods: {
            checkLike() {
                this.likeState = localStorage.getItem(`vef_${this.articleID}`);
            },

            onLike() {
                let like = (this.likeState == 1) ? 0 : 1;
                let adj = (this.likeState == 0) ? 1 : 0; //adjust dislike count

                this.$http.get(`api/Article/SetLike/${this.articleID}/${like}/${adj}`, (res) => {
                    if (like == 1)
                        localStorage.setItem(`vef_${this.articleID}`, 1);
                    else
                        localStorage.removeItem(`vef_${this.articleID}`);

                    this.dislikeCount = res.dislike;
                    this.likeCount = res.like;
                    this.checkLike();
                });
            },

            onDislike() {
                let dislike = (this.likeState == 0) ? 0 : 1;
                let adj = (this.likeState == 1) ? 1 : 0; //adjust like count

                this.$http.get(`api/Article/DisLike/${this.articleID}/${dislike}/${adj}`, (res) => {
                    if (dislike == 1)
                        localStorage.setItem(`vef_${this.articleID}`, 0);
                    else
                        localStorage.removeItem(`vef_${this.articleID}`);

                    this.dislikeCount = res.dislike;
                    this.likeCount = res.like;
                    this.checkLike();
                });
            }
        }
    }
</script>

<style scoped>
    img {
        color: #ff0000;
        margin-top: -5px;
        width: 30px;
        height: 30px;
    }
    .icon {
        width: 30px;
        height: 30px;
    }
    .up {
        margin-top: -13px;
    }
    .dislike {
        vertical-align: top;
    }
</style>
