<template>
	<div class="site-map">
		<div class="d-flex">
			<div v-for="(p, index) in paths" :key="index" class="item">{{p}}</div>
			<!--div class="ml-auto">{{currentTime | moment('DD MMM YYYY')}}</div>-->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SiteMap',
		computed: {
			paths() {
				return this.$store.getters.sitePath;
			}
		},
		data() {
			return {
				currentTime: new Date()
			}
		},
		mounted() {

		}
	}
</script>

<style scoped>
	.site-map {
		font-size: 12px;
		margin-bottom: 10px;
	}
	.item:not(:last-child):after {
		content: ' > ';
		margin-right: 5px;
	}
</style>