<template>
	<div class="cat-summary">
		<div v-for="p in items" :key="p.catName" class="block">
			<div class="cat">{{p.catName}}</div>
			<b-row>
				<b-col cols="4" class="item">
					<div><img :src="getImgUrl(p.items[0].itemID)" alt="pic" class="small" /></div>
				</b-col>
				<b-col cols="4" v-for="q in p.items" :key="q.itemID" class="item">
					<div>
						<div class="title">
							<router-link :to="`/article/article/${q.itemID}`">{{q.title}}</router-link>
						</div>
						<div class="intro">{{q.intro}}</div>
						<div class="create-date">
							{{q.createDate | moment("MMM DD, YYYY")}}
							<span v-if="q.authorID">| by {{q.authorName}}</span>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
	import Constants from '../Contants'
	import funcMixin from '../services/shared'

	export default {
		name: 'CategorySummary',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.load();
		},
		mixins: [funcMixin],
		methods: {
			getImgUrl(id) {
				return Constants.API_HOST + `/Public/Images/Articles/small/a${id}.jpg`;
			},

			load() {
				this.$http.get('api/Category/GetCategorySummary', (res) => {
					this.items = res;
				});
			}
		}
	}
</script>

<style scoped>
	.block {
		margin-bottom: 10px;
	}
	.cat {
		font-weight: bold;
		padding-bottom: 5px;
		margin-bottom: 10px;
		border-bottom: solid 1px #ddd;
	}
	img.small {
		width: 100%;
		padding: 5px 0 5px 0;
	}
	.title {
		font-size: 14px;
		font-family: Georgia, Times New Roman, Segoe UI;
		font-weight: bold;
		padding-bottom: 10px;
	}
	.create-date {
		padding-top: 20px;
		color: #aaa;
		font-size: 11px;
	}
</style>