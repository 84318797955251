<template>
	<div>
		<div class="site-map">DOWNLOADS</div>
		<div>
			<ol>
				<li v-for="p in items" :key="p.id" class="item">
					<a href="#" @click="onDownload(p.id, p.fileName)">{{p.title}}</a>
					<img :src="getIcon(p.fileName)" alt="" />
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DownloadPage',
		data() {
			return {
				items: []
			}
		},
		mounted() {
			this.$store.commit('setPath', 'DOWNLOADS');
			this.load();
		},
		methods: {
			load() {
				this.$http.get('api/Misc/GetDownloadList', (res) => {
					this.items = res;
				});
			},

			getIcon(fileName) {
				var icon = '';
				var re = /(?:\.([^.]+))?$/;
				var ext = re.exec(fileName)[1];

				if (ext == 'pdf')
					icon = 'pdf-32.png';
				else if (ext == 'doc' || ext == 'docx')
					icon = 'word-32.png';
				else if (ext == 'ppt' || ext == 'pptx')
					icon = 'ppt-32.png';
				else if (ext == 'zip')
					icon = 'zip-32.png';
				else
					icon = 'unknown-32.png';

				return '/Images/icons/' + icon;
			},

			onDownload(id, fileName) {
				this.$http.download(`api/Misc/DownloadFile/${id}`, (data) => {
					// eslint-disable-next-line no-console
					console.log('done');

					var fileURL = window.URL.createObjectURL(new Blob([data]));
					var fileLink = document.createElement('a');

					fileLink.href = fileURL;
					fileLink.setAttribute('download', fileName);
					//document.body.appendChild(fileLink);

					fileLink.click();
				})
			}
		}
	}
</script>

<style scoped>
	.caption {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.item {
		padding: 5px 0;
	}
	img {
		width: 25px;
		margin-left: 10px;
	}
</style>
